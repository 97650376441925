import { PropertyDefinitionSchema } from '@/helpers/services/api';

export const constants = {
  inputPlaceholder: 'Property search...',
  noPropsText: 'No Properties',
  removeColumnText: 'Remove column'
};

export const propertiesObj = {
  0: 'input',
  1: 'output',
  2: 'custom'
};

export const defaultProperty: PropertyDefinitionSchema = {
  column_type: 'numeric',
  property_column_name: 'TEXT_LENGTH',
  property_name: 'Text Length',
  property_type: 'input',
  is_hidden: false,
  in_dashboard: false,
  description: '',
  url: ''
};
