import React from 'react';

import { alpha } from '@mui/material';

import { getUserPreferences } from './localStorage';

export const getMarkedText = (
  text: string,
  marking_indexes?: { start: number; end: number } | null,
  property?: string
) => {
  if (!marking_indexes) return text;

  const { start, end } = marking_indexes;

  if (start < 0 || end < 0 || start > end) {
    return text;
  }

  return (
    <>
      {text.slice(0, start)}
      <span style={{ backgroundColor: alpha(propsMarkingColor(property), 0.3) }}>{text.slice(start, end + 1)}</span>
      {text.slice(end + 1)}
    </>
  );
};

export const propsMarkingColor = (property?: string) => {
  const { darkMode } = getUserPreferences();

  const isDarkMode = Boolean(darkMode).toString();

  switch (property) {
    case 'Grounded in Context':
      return isDarkMode === 'true' ? '#367BF4' : '#367BF4';
    case 'Retrieval Relevance':
      return isDarkMode === 'true' ? '#00B2C2' : '#00B2C2';
    default:
      return '';
  }
};
