import { PropertyConfigSchemaOutput } from '@/helpers/services/api';

import { SamplesDetailsBodyPropertyType } from './SampleDetailsBodyProperties';

export const handleFindElement = (
  propertiesList: SamplesDetailsBodyPropertyType[],
  el: { text_property: string; kind: string } | PropertyConfigSchemaOutput
) => {
  return propertiesList.find(
    (elem: { name: string; type: string }) => elem.name === el.text_property && elem.type === el.kind
  );
};
