import { alpha, Stack, styled } from '@mui/material';

import { StyledText } from '@/components/lib';

import { propsMarkingColor } from '@/helpers/utils/getMarkedText';

export const StyledContainer = styled(Stack, {
  shouldForwardProp: prop => prop !== 'expand' && prop !== 'property' && prop !== 'comparison' && prop !== 'clickable'
})<{ expand?: boolean; property?: string; clickable?: boolean }>(({ expand, clickable, property, theme }) => ({
  margin: 0,
  width: '100%',
  minWidth: '145px',
  maxWidth: '210px',
  transition: '0.3s',
  padding: '6px 10px !important',
  borderRadius: '8px',
  justifyContent: 'space-between',
  height: expand ? '345px' : '65px',
  border: `2px solid ${property ? propsMarkingColor(property) : theme.palette.grey[300]}`,
  backgroundColor: property
    ? alpha(propsMarkingColor(property), 0.1)
    : expand
      ? theme.palette.grey[100]
      : 'transparent',

  '.MuiTypography-h3': { fontWeight: 700 },
  '.MuiTypography-root': { whiteSpace: 'noWrap' },
  '.MuiTypography-subtitle2': { fontSize: '14px', lineHeight: '16px' },
  '.MuiTypography-body2 ': { color: expand ? theme.palette.grey[500] : theme.palette.grey[400] },
  '.MuiTypography-subtitle1': { fontSize: '14px', color: theme.palette.grey[500], lineHeight: '16px' },

  ':hover > div > svg': { display: 'block' },
  ':hover': clickable
    ? {
        cursor: 'pointer',
        borderColor: theme.palette.grey[400],
        backgroundColor: theme?.palette.grey[200],

        '.MuiTypography-body2': { color: theme.palette.grey[500] }
      }
    : { borderColor: theme.palette.grey[400] }
}));

export const StyledRowContainer = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',

  '.MuiTypography-h3': { padding: '0 2px' }
});

export const StyledReasonText = styled(StyledText)(({ theme }) => ({
  margin: '12px 0',
  overflowY: 'auto',
  padding: '8px 2px 0 0',
  whiteSpace: 'pre-wrap !important',
  borderTop: `1px solid ${theme.palette.grey[400]}`,

  '&::-webkit-scrollbar': { width: '5px' },

  '&::-webkit-scrollbar-thumb': {
    borderRadius: '8px',
    backgroundColor: theme.palette.grey[300]
  }
}));

export const StyledValueText = styled(StyledText)({
  overflow: 'hidden !important'
});

export const StyledValueTextContainer = styled(Stack)({
  overflow: 'hidden !important'
});

export const StyledTitleContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',

  '> svg': {
    display: 'none',
    cursor: 'pointer',
    fill: theme.palette.grey[300],

    ':hover': {
      fill: theme.palette.grey[500]
    }
  }
}));
