import { Menu, MenuItem, Stack, alpha, styled } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { StyledText } from '@/components/lib';

export const StyledScoreTypeDropdownContainer = styled(Stack)(({ theme }) => ({
  gap: '0',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  cursor: 'pointer',

  ' svg': {
    color: theme.palette.grey[400]
  }
}));

export const StyledDescriptionTitleText = styled(StyledText)(({ theme }) => ({
  color: theme.palette.grey[400]
}));

export const StyledRootMenu = styled(Menu)({
  marginTop: '0',

  '& .MuiPaper-root': {
    overflow: 'visible',
    padding: '0 8px',
    borderRadius: '10px',
    boxShadow: '2px 2px 30px -10px #29354340'
  }
});

export const StyledMenuItem = styled(MenuItem)<{ selected: boolean }>(({ selected, theme }) => ({
  position: 'relative',
  padding: '8px',

  ' p': {
    color: selected ? theme.palette.primary.main : theme.palette.grey[400]
  },

  ':hover': {
    background: alpha(theme.palette.primary.main, 0.07),
    ' p': {
      color: theme.palette.primary.main
    }
  }
}));

export const StyledNumberContainer = styled(Stack)(({ theme }) => ({
  borderRadius: '8px',
  border: `2px solid ${theme.palette.grey[300]}`,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '8px 12px',
  background: 'transparent',
  gap: '4px'
}));

export const StyledInfoIcon = styled(InfoOutlinedIcon)(({ theme }) => ({
  height: '24px',

  fill: theme.palette.grey[400]
}));
