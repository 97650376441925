import React, { useState } from 'react';

import { useRouter } from 'next/router';

import { StackProps } from '@mui/material';
import { HighlightOff } from '@mui/icons-material';

import { InOutIcon } from '@/components/shared/InOutIcon/InOutIcon';

import { StyledText, StyledToolTip } from '@/components/lib';
import {
  StyledValueText,
  StyledContainer,
  StyledReasonText,
  StyledRowContainer,
  StyledTitleContainer,
  StyledValueTextContainer
} from './SampleDetailsDialogBodyCard.styles';

import { routes } from '@/helpers/routes';
import { propsMarkingColor } from '@/helpers/utils/getMarkedText';
import { PropertiesMetadataSchema, PropertyDefinitionSchema, PropertyType } from '@/helpers/services/api';

import { constants as samplesConstants } from '@/components/Samples/SamplesTable/samplesTable.constants';

interface SamplesCard {
  title: string;
  value: number | string;
  allData?: number | string;
}

interface SamplesTableDialogBodyCardProps extends StackProps {
  reason?: string;
  card: SamplesCard;
  kind: PropertyType;
  staticItem?: boolean;
  propertyToMark?: string;
  switchToTranslated?: boolean;
  ignoreReasoningTooltip?: boolean;
  allMarkingIndexes?: PropertiesMetadataSchema;
  propertyDefinition?: PropertyDefinitionSchema;
  handleDelete?: () => void;
  setSection?: (section: string) => void;
  setPropertyToMark?: (property: string) => void;
  setIsTranslation?: (isTranslation: boolean) => void;
}

const constants = {
  moreDetails: 'More details',
  markingExplanation: (propName?: string) =>
    propName === 'Grounded in Context'
      ? '* The highlighted  sentences on the output received the lowest score'
      : '* The most relevant paragraph is highlighted in the document',
  tooltipText: (description?: string, reason?: string) =>
    `${description ?? ''}${reason ? `\n\n Score Reasoning - \n\n ${reason}` : ''}`
};

const { sampleKey, informationRetrievalKey } = samplesConstants;

export const SampleDetailsDialogBodyCard = (props: SamplesTableDialogBodyCardProps) => {
  const {
    card,
    kind,
    reason,
    propertyToMark,
    allMarkingIndexes,
    propertyDefinition,
    switchToTranslated,
    ignoreReasoningTooltip,
    setSection,
    handleDelete,
    setIsTranslation,
    setPropertyToMark,
    ...otherProps
  } = props;

  const [isReasonView, setIsReasonView] = useState(false);

  const { push } = useRouter();

  const title = card?.title;
  const isExpandLogic = reason && ignoreReasoningTooltip;
  const isMarkingAvailable =
    ((title === 'Grounded in Context' && typeof allMarkingIndexes?.grounded_in_context?.start === 'number') ||
      (title === 'Retrieval Relevance' && typeof allMarkingIndexes?.retrieval_relevance?.start === 'number')) &&
    setPropertyToMark;

  const handleRedirect = () => {
    push(
      `${routes.config.properties}?drillDownPropertyName=${propertyDefinition?.property_name}&drillDownPropertyType=${kind}`
    );
  };

  const handleContainerClick = () => {
    if (isExpandLogic) {
      setIsReasonView(!isReasonView);
    } else if (isMarkingAvailable) {
      if (propertyToMark !== title) {
        setPropertyToMark && setPropertyToMark(title as string);
        switchToTranslated && setIsTranslation && setIsTranslation(true);
        setSection && setSection(title === 'Retrieval Relevance' ? informationRetrievalKey : sampleKey);
      } else {
        setPropertyToMark('');
      }
    }
  };

  return (
    <StyledContainer
      {...otherProps}
      onClick={handleContainerClick}
      clickable={!!isExpandLogic || !!isMarkingAvailable}
      expand={!!isReasonView || propertyToMark === title}
      property={title === propertyToMark ? propertyToMark : ''}
      data-testid={`SampleDetailsDialogBodyCard-${title}-${kind}`}
    >
      <StyledToolTip
        maxHeight="400px"
        placement="right"
        text={propertyDefinition?.property_name}
        link={{ onClickOverride: handleRedirect, label: constants.moreDetails }}
        descriptions={constants.tooltipText(propertyDefinition?.description, !ignoreReasoningTooltip ? reason : '')}
      >
        <StyledTitleContainer>
          <StyledText text={title} type="bodyBold" />
          {handleDelete && (
            <HighlightOff fontSize="small" onClick={handleDelete} data-testid={`HighlightOff-${title}-${kind}`} />
          )}
        </StyledTitleContainer>
      </StyledToolTip>
      <StyledRowContainer>
        <StyledToolTip text={typeof card?.value === 'string' ? card?.value : ''}>
          <StyledValueTextContainer>
            <StyledValueText text={card?.value} type="h3" />
          </StyledValueTextContainer>
        </StyledToolTip>
        <InOutIcon kind={kind} noTooltip />
      </StyledRowContainer>
      {isReasonView || propertyToMark === title ? (
        <StyledReasonText
          type={propertyToMark === title ? 'tinyBold' : 'tiny'}
          text={reason || constants.markingExplanation(propertyToMark)}
          color={propsMarkingColor(propertyToMark === title ? propertyToMark : '')}
        />
      ) : (
        <></>
      )}
    </StyledContainer>
  );
};
