import React, { useEffect, useRef, useState } from 'react';

import { ArrowBackIosRounded, ClearRounded } from '@mui/icons-material';

import { StyledSkeleton, StyledText, StyledToolTip } from '@/components/lib';
import { SelectedColumnType } from '@/components/Samples/Samples.types';

import { getAppStorage, getStorageItem, storageKeys } from '@/helpers/utils/localStorage';
import {
  ApplicationVersionExtendedSchema,
  ApplicationVersionSchema,
  PropertyConfigSchemaOutput,
  InteractionSchema,
  getSimilarApiV1VersionsComparisonPut,
  listAppVersionsExtended,
  useListPropertiesConfigs,
  PropertyDefinitionSchema
} from '@/helpers/services/api';

import { SampleDetailsDialogComparisonInteraction } from './SampleDetailsDialogComparisonInteraction/SampleDetailsDialogComparisonInteraction';
import { SampleDetailsDialogComparisonInteractionHeader } from './SampleDetailsDialogComparisonInteractionHeader/SampleDetailsDialogComparisonInteractionHeader';
import { SamplesDetailsBodyPropertyType } from '../SampleDetailsDialogBody/SamplesDetailsBodyItem/SampleDetailsBodyProperties/SampleDetailsBodyProperties';
import { handleFindElement } from '../SampleDetailsDialogBody/SamplesDetailsBodyItem/SampleDetailsBodyProperties/SampleDetailsBodyProperties.helpers';
import { NoDataAvailable } from '../../NoDataAvailable/NoDataAvailable';

import {
  StyledContainer,
  StyledInputContainer,
  StyledVersionsContainer,
  StyledInputTextContainer,
  StyledArrowContainer,
  StyledIconButton,
  StyledArrowIconButton,
  StyledNoVersionsContainer
} from './SampleDetailsDialogComparison.styles';
import {
  StyledPaginationButton,
  StyledPaginationContainer
} from '../SampleDetailsDialogHeader/SampleDetailsDialogHeader.styles';

import { constants } from './sampleDetailsDialogComparison.constants';
import { constants as headerConstants } from '../SampleDetailsDialogHeader/sampleDetailsDialogHeader.constants';

interface SampleDetailsDialogComparisonProps {
  interaction: InteractionSchema;
  interactionToCompare?: { application_version_id: number; user_interaction_id: string };
  updateAnnotation?: (interactionId: string, annotation: string, reason: string, isEstimated?: boolean) => void;
  closeDialog?: () => void;
  getPropertyInfo?: (name: string) => PropertyDefinitionSchema;
  setCurrentPage?: React.Dispatch<React.SetStateAction<number>>;
  pageCount: number;
  currentPage: number;
  handleBack?: () => void;
  isVersionComparison?: boolean;
  isInteractionsLoading?: boolean;
  curVersionsToComparison?: ApplicationVersionExtendedSchema[];
  differentByPropData?: PropertyConfigSchemaOutput;
  metricType?: string;
  selectedColumns?: SelectedColumnType[];
}

const { input, propsLimit, version, keys, noVersionToCompare } = constants;

const { backButtonLabel, nextButtonLabel, paginationText } = headerConstants;

export const SampleDetailsDialogComparison = (props: SampleDetailsDialogComparisonProps) => {
  const {
    interaction,
    interactionToCompare,
    pageCount,
    currentPage,
    isVersionComparison,
    curVersionsToComparison,
    isInteractionsLoading,
    differentByPropData,
    metricType,
    selectedColumns,
    handleBack,
    closeDialog,
    setCurrentPage,
    getPropertyInfo,
    updateAnnotation
  } = props;

  const { appId, type } = getAppStorage();

  const { data: listProperties = [] } = useListPropertiesConfigs({ app_id: appId });

  const [includeEstScore, setIncludeEstScore] = useState(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [versions, setVersions] = useState<ApplicationVersionSchema[]>([]);
  const [versionsExtendedList, setVersionsExtendedList] = useState<ApplicationVersionExtendedSchema[]>([]);
  const [allPropsArr, setAllPropsArr] = useState<SamplesDetailsBodyPropertyType[]>([]);
  const [comparisonProps, setComparisonProps] = useState<SamplesDetailsBodyPropertyType[]>([]);
  const [secondSelectedVersion, setSecondSelectedVersion] = useState<ApplicationVersionSchema>();
  const [comparisonContent, setComparisonContent] = useState<{ base: string; diff: string; show: boolean }>({
    base: '',
    diff: '',
    show: false
  });
  const [selectedVersion, setSelectedVersion] = useState('');
  const [initialVersion, setInitialVersion] = useState<
    | ApplicationVersionSchema
    | {
        name: string;
        id: number;
      }
  >({} as ApplicationVersionSchema);
  const [mainSelectedTag, setMainSelectedTag] = useState<string>(keys.output);
  const [isPropertyOpen, setIsPropertyOpen] = useState<boolean>(false);

  const firstContentRef = useRef<HTMLDivElement>(null);
  const secondContentRef = useRef<HTMLDivElement>(null);

  const handleScroll = (containerRef: React.RefObject<HTMLDivElement>) => {
    if (!containerRef.current) return;

    const scrollTop = containerRef.current.scrollTop;
    if (firstContentRef.current) firstContentRef.current.scrollTop = scrollTop;
    if (secondContentRef.current) secondContentRef.current.scrollTop = scrollTop;
  };

  const propertiesStorage = getStorageItem(storageKeys.propFilters);

  const noInteractionToCompare = Number(versions?.length) < 2;
  const allVersions = Array.isArray(versions) ? versions : [];
  const comparedVersion =
    Array?.isArray(versions) && versions?.filter(v => v?.id === interactionToCompare?.application_version_id)[0];

  const propsFromStorage = appId && propertiesStorage?.[appId]?.prop_filters;

  const curSelectedColumns: SamplesDetailsBodyPropertyType[] = Array.isArray(selectedColumns)
    ? selectedColumns?.reduce((acc: SamplesDetailsBodyPropertyType[], el) => {
        const findCurElemInAllProp = handleFindElement(allPropsArr, {
          text_property: el.propertyName || '',
          kind: el.propertyType?.toLowerCase() || ''
        });
        if (findCurElemInAllProp && findCurElemInAllProp.value !== null) {
          return [...acc, findCurElemInAllProp];
        }

        return [...acc];
      }, [] as SamplesDetailsBodyPropertyType[])
    : [];

  const selectedDataPageProps: SamplesDetailsBodyPropertyType[] = Array.isArray(propsFromStorage)
    ? propsFromStorage?.reduce((acc: SamplesDetailsBodyPropertyType[], el: { text_property: string; kind: string }) => {
        const findCurElemInAllProp = handleFindElement(allPropsArr, el);
        const findCurElemInSelectedColumns = handleFindElement(curSelectedColumns, el);
        if (findCurElemInAllProp && findCurElemInAllProp.value !== null && !findCurElemInSelectedColumns) {
          return [...acc, findCurElemInAllProp];
        }

        return [...acc];
      }, [] as SamplesDetailsBodyPropertyType[])
    : [];

  const curSelectedDataPageProps = [...(curSelectedColumns || []), ...(selectedDataPageProps || [])];

  const curListOfProperties: SamplesDetailsBodyPropertyType[] = Array.isArray(listProperties)
    ? listProperties.reduce((acc, el) => {
        if (curSelectedDataPageProps.length && !handleFindElement(curSelectedDataPageProps, el)) {
          const findCurElemInAllProp = handleFindElement(allPropsArr, el);

          return [
            ...acc,
            ...(findCurElemInAllProp && findCurElemInAllProp.value !== null ? [findCurElemInAllProp] : [])
          ];
        } else if (!curSelectedDataPageProps.length) {
          const curElData = handleFindElement(allPropsArr, el);

          return [...acc, ...(curElData ? [curElData] : [])];
        }

        return [...acc];
      }, [] as SamplesDetailsBodyPropertyType[])
    : ([] as SamplesDetailsBodyPropertyType[]);

  const getComparisonVersions = async () => {
    setIsLoading(true);
    await getSimilarApiV1VersionsComparisonPut({
      user_interaction_id: interaction?.user_interaction_id,
      application_id: appId,
      env_type: type
    }).then(res => {
      Array.isArray(res) && setVersions(res);
    });
    setIsLoading(false);
  };

  const handleDeleteProperty = (item: SamplesDetailsBodyPropertyType) => {
    const newComparisonPropsArray = comparisonProps.filter(el => el.name !== item.name || el.type !== item.type);
    setComparisonProps(newComparisonPropsArray);
  };

  const handleAddProperty = (item: SamplesDetailsBodyPropertyType) => setComparisonProps([...comparisonProps, item]);

  const versionsDropdownData = Array?.isArray(allVersions)
    ? allVersions?.filter(v => v?.id !== initialVersion?.id && v?.name !== selectedVersion)
    : [];

  const handleNextPage = () => setCurrentPage && setCurrentPage((prev: number) => Math.min(prev + 1, pageCount - 1));

  const handlePrevPage = () => setCurrentPage && setCurrentPage((prev: number) => Math.max(prev - 1, 0));

  const handleInitialVersionChange = (value: string) => {
    const newInitialVersion = allVersions.find(el => el.name === value);

    newInitialVersion && setInitialVersion(newInitialVersion);
  };

  const isVersionsExist = Boolean(versions.length) && versions.length > 1;

  useEffect(() => {
    if (isVersionComparison && Array.isArray(curVersionsToComparison)) {
      setVersions(
        curVersionsToComparison.map(
          el =>
            ({
              ai_model: el.ai_model,
              application_id: el.application_id,
              created_at: el.created_at,
              id: el.id,
              name: el.name,
              updated_at: el.updated_at
            }) as ApplicationVersionSchema
        )
      );
      setIsLoading(false);
    } else {
      getComparisonVersions();
    }
  }, [interaction?.user_interaction_id]);

  useEffect(() => {
    if (listProperties?.length && allPropsArr.length && selectedDataPageProps && !comparisonProps.length) {
      setComparisonProps(
        [...curSelectedColumns, ...selectedDataPageProps, ...curListOfProperties].slice(0, propsLimit)
      );
    }
  }, [listProperties, allPropsArr]);

  useEffect(() => {
    const curInitialVersion = Array?.isArray(versions)
      ? versions?.filter(v => v?.id === interaction?.application_version_id)[0]
      : {
          name: `${interaction?.application_version_id}`,
          id: interaction?.application_version_id
        };
    setInitialVersion(curInitialVersion);

    setSelectedVersion(
      comparedVersion
        ? comparedVersion?.name
        : secondSelectedVersion
          ? secondSelectedVersion.name
          : versionsDropdownData
            ? versionsDropdownData.filter(el => curInitialVersion?.name !== el.name)[0]?.name
            : ''
    );
  }, [versions]);

  useEffect(() => {
    if (secondSelectedVersion && !isLoading && !isVersionComparison) {
      listAppVersionsExtended({
        only_user_annotations_score: !includeEstScore,
        overlapping_version_ids: [initialVersion?.id, secondSelectedVersion.id],
        app_id: appId
      }).then(res => {
        if (res?.length) {
          setVersionsExtendedList(res);
        }
      });
    }
  }, [secondSelectedVersion?.id, includeEstScore, initialVersion?.id]);

  useEffect(() => {
    if (isVersionComparison && isInteractionsLoading !== undefined) {
      setIsLoading(isInteractionsLoading);
    }
  }, [isInteractionsLoading]);

  return (
    <>
      {!isVersionComparison && (
        <>
          <StyledArrowContainer>
            <StyledArrowIconButton onClick={handleBack}>
              <ArrowBackIosRounded fontSize="medium" />
            </StyledArrowIconButton>
            <StyledIconButton onClick={closeDialog}>
              <ClearRounded fontSize="medium" />
            </StyledIconButton>
          </StyledArrowContainer>
          {!isVersionsExist && !isLoading ? (
            <StyledNoVersionsContainer>
              <NoDataAvailable customTitle={noVersionToCompare} />
            </StyledNoVersionsContainer>
          ) : (
            <StyledVersionsContainer>
              <SampleDetailsDialogComparisonInteractionHeader
                version={version}
                initialVersion={initialVersion}
                selectedVersion={selectedVersion}
                handleInitialVersionChange={handleInitialVersionChange}
                setSelectedVersion={setSelectedVersion}
                versionsDropdownData={versionsDropdownData}
                versionsExtendedList={versionsExtendedList}
                includeEstScore={includeEstScore}
                setIncludeEstScore={setIncludeEstScore}
                pageCount={pageCount}
              />
              <SampleDetailsDialogComparisonInteractionHeader
                version={version}
                isSelectable
                initialVersion={initialVersion}
                selectedVersion={selectedVersion}
                handleInitialVersionChange={handleInitialVersionChange}
                setSelectedVersion={setSelectedVersion}
                versionsDropdownData={versionsDropdownData}
                isBorderLeft
                versionsExtendedList={versionsExtendedList}
                includeEstScore={includeEstScore}
                setIncludeEstScore={setIncludeEstScore}
                pageCount={pageCount}
              />
            </StyledVersionsContainer>
          )}
        </>
      )}
      {isLoading ? (
        <StyledSkeleton height={!isVersionComparison ? '600.6px' : '539px'} width="100%" />
      ) : (
        isVersionsExist && (
          <StyledContainer>
            <StyledInputContainer is_two_items={pageCount ? 'false' : 'true'}>
              <StyledText text={input} type="bodyBold" />
              <StyledToolTip text={interaction?.input?.data}>
                <StyledInputTextContainer>
                  <StyledText text={interaction?.input?.data} />
                </StyledInputTextContainer>
              </StyledToolTip>
              {!!pageCount && (
                <StyledPaginationContainer>
                  <StyledPaginationButton
                    label={backButtonLabel}
                    onClick={handlePrevPage}
                    disabled={currentPage === 0}
                  />
                  <StyledText text={paginationText(currentPage, pageCount)} type="bodyBold" />
                  <StyledPaginationButton
                    label={nextButtonLabel}
                    onClick={handleNextPage}
                    disabled={currentPage === pageCount - 1}
                  />
                </StyledPaginationContainer>
              )}
            </StyledInputContainer>
            <StyledVersionsContainer>
              <SampleDetailsDialogComparisonInteraction
                scrollRef={firstContentRef}
                initialVersion={initialVersion}
                comparisonProps={comparisonProps}
                comparisonContent={comparisonContent}
                interactionToUseId={interaction?.user_interaction_id}
                handleScroll={handleScroll}
                setAllPropsArr={setAllPropsArr}
                getPropertyInfo={getPropertyInfo}
                updateAnnotation={updateAnnotation}
                handleAddProperty={handleAddProperty}
                setComparisonContent={setComparisonContent}
                handleDeleteProperty={handleDeleteProperty}
                selectedVersion={selectedVersion}
                versionCompareInteraction={interaction}
                isVersionComparison={isVersionComparison}
                differentByPropData={differentByPropData}
                setMainSelectedTag={setMainSelectedTag}
                mainSelectedTag={mainSelectedTag}
                setIsPropertyOpen={setIsPropertyOpen}
                isPropertyOpen={isPropertyOpen}
              />
              <SampleDetailsDialogComparisonInteraction
                versions={allVersions}
                scrollRef={secondContentRef}
                comparisonProps={comparisonProps}
                comparisonContent={comparisonContent}
                noInteractionToCompare={noInteractionToCompare}
                initialVersion={{ ...initialVersion, user_interaction_id: interaction?.user_interaction_id }}
                interactionToUseId={interactionToCompare?.user_interaction_id || interaction?.user_interaction_id}
                handleScroll={handleScroll}
                getPropertyInfo={getPropertyInfo}
                setComparisonContent={setComparisonContent}
                setSecondSelectedVersion={setSecondSelectedVersion}
                selectedVersion={selectedVersion}
                versionCompareInteraction={interactionToCompare as InteractionSchema}
                isVersionComparison={isVersionComparison}
                differentByPropData={differentByPropData}
                setMainSelectedTag={setMainSelectedTag}
                mainSelectedTag={mainSelectedTag}
                setIsPropertyOpen={setIsPropertyOpen}
                isPropertyOpen={isPropertyOpen}
                metricType={metricType}
              />
            </StyledVersionsContainer>
          </StyledContainer>
        )
      )}
    </>
  );
};
